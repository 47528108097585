import { Box, Grid, Text, VStack, FormControl, FormLabel, Select } from "@chakra-ui/react";
import { defer, useLoaderData, Await, useSearchParams } from 'react-router-dom';
import { Suspense } from 'react';
import { Update } from "../components/Update";
import HeaderImage from "../components/HeaderImage";

const API_URL = process.env.REACT_APP_API_URL;

function UpdatesPage() {
  const { updates } = useLoaderData();
  const [ updateTypeParams, setUpdateTypeParams ] = useSearchParams();
  const updateType = updateTypeParams.get('updateType') || '';

  const onChangeHandler = (event) => {
    updateTypeParams.set('updateType', event.target.value);
    setUpdateTypeParams(updateTypeParams);
  }

  return (
    <VStack w='full' h='full' spacing={4}>
      <HeaderImage imageUrl={'/Updates.jpg'} />
      <Box p='3rem' px='5rem' alignItems='baseline' w='full'>
        <Text fontSize='2xl' fontWeight='bold' color='brand.900'>
          The WFL's Latest Updates
        </Text>
        <Box w='20%'>
          <FormControl mt={4} key='eventType'>
            <FormLabel><Text fontSize='2xl' p='0'>Filter</Text></FormLabel>
            <Select name='eventType' value={updateType} onChange={onChangeHandler} required>
              <option value=''>All</option>
              <option value='articles'>Articles</option>
              <option value='sampleCases'>Sample Cases</option>
              <option value='marketing'>Marketing</option>
              <option value='pastEvents'>Past Events</option>
              <option value='upcomingEvents'>Upcoming Events</option>
              <option value='skills'>Skills</option>
              <option value='competitionResults'>Competition Results</option>
              <option value='achievements'>Achievements</option>
              <option value='studentProfiles'>Student Profiles</option>
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Suspense fallback={<p style={{textAlign: 'center'}}>Loading...</p>}>
        <Grid templateColumns='repeat(3, 1fr)' gap={6} p='5rem' pt={0} w='full'>
          <Await resolve={updates}>
            {(loadedUpdates) => (
              loadedUpdates.map((update) => (
                <Update key={update.id} update={update} />
              ))
            )}
          </Await>
        </Grid>
      </Suspense>

    </VStack>
  );
}

export default UpdatesPage;

async function loadEvents(eventType, updateType) {
  let url = `${API_URL}/${eventType}`;

  if (updateType) {
    url += `?updateType=${updateType}`;
  }

  const response = await fetch(url);

  if (!response.ok) {
    return [];
  }

  const data = await response.json();

  return data?.data?.events || [];
}

export const loader = ({ request }) => {
  const { searchParams } = new URL(request.url);
  const updateType = searchParams.get('updateType');

  return defer({
    updates: loadEvents('updates', updateType),
  });
};
